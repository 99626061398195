<template>
  <div class="body">
    <div class="orderItem">
      <span class="orderItem-label">就诊人：</span>刘欢
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付单号：</span>123
    </div>
    <div class="orderItem">
      <span class="orderItem-label">检查项目：</span>123
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付时间：</span>123
    </div>
    <div class="orderItem">
      <span class="orderItem-label">应付金额：</span
      ><span style="color: #e27a66">￥123</span>
    </div>
    <van-cell-group style="margin: 20px 0">
      <van-cell center>
        <template #title>
          <img
            src="@/assets/img/appwx_logo.png"
            alt=""
            class="appwx_logo"
          /><span class="appwx_text">微信支付</span>
        </template>
        <template #default>
          <van-radio-group v-model="checked">
            <van-radio :name="0" checked-color="#32D8C0"></van-radio>
          </van-radio-group>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="confirm-tips">请确认以上信息是否正确，再选择是否支付</div>
    <div>
      <van-button class="pay-btn" @click="show = true" color="#31AA05"
        >确认支付</van-button
      >
    </div>
    <div>
      <van-button class="pay-btn" color="#8A8A8A" @click="handleCancle"
        >取消支付</van-button
      >
    </div>
    <van-dialog
      class="dialog"
      v-model="show"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <div>请确认信息是否正确</div>
      <p><span class="dialog-label">就诊人：</span> <b>刘欢</b></p>
      <p class="ellipsis">
        <span class="dialog-label">检查项目：</span> <b>123</b>
      </p>
      <p>
        <span class="dialog-label">应付金额：</span>
        <span style="color: #e27a66">￥123</span>
      </p>
      <van-button type="primary" class="cancle-btn" @click="show = false"
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="handleConfirm"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "neonatalWxPayPage",
  data() {
    return {
      checked: 0,
      show: false, // 信息确认弹框的显示隐藏
    };
  },
  methods: {
    /**
     * 确认支付
     */
    handleConfirm() {},
    /**
     * 取消支付
     */
    handleCancle() {},
  },
};
</script>

<style scoped>
.body {
  width: 9rem;
  height: auto;
  border-radius: 5px;
  margin: 0px auto;
  padding: 12px 3px;
  font-size: 0.4rem;
  line-height: 0.9rem;
}
.orderItem {
  text-align: left;
}
.orderItem-label {
  display: inline-block;
  width: 2.2rem;
  text-align: right;
}
.appwx_logo {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 10px;
}
.appwx_text {
  font-size: 0.5rem;
  vertical-align: middle;
}
.pay-btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.confirm-tips {
  color: #5ba5cf;
  font-size: 0.4rem;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}
.dialog p {
  text-align: left;
  font-size: 0.4rem;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.cancle-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
/deep/ .van-cell {
  background-color: transparent;
  padding: 10px 0;
}
/deep/ .van-cell-group {
  background-color: transparent;
}
/deep/ .van-cell__title {
  text-align: left;
}
/deep/ .van-radio {
  float: right;
  clear: both;
}
/deep/ .van-button {
  padding: 5px 0;
}
.pay-btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.confirm-tips {
  color: #5ba5cf;
  font-size: 0.4rem;
}
</style>
